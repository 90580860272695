<template style="display: inline-table;">
	<!-- <v-row> -->
	<v-card>
		<v-row>
			<v-col cols="12">
				<s-toolbar
					:label="'EMBARQUE - ' + items.PckShipmentNumber"
					dark
					:color="'#BAB6B5'"
					close
					@close="close()"
					:save="items.PckState != 0"
					@save="save()"
					class="mb-0"
				></s-toolbar>
			</v-col>

			<v-col cols="12">
				<v-container class="pb-2">
					<v-row justify="center">

                        <v-col class="s-col-form">
							<s-text label="Fecha" v-model="items.PckDate" readonly></s-text>
						</v-col>

                        <v-col class="s-col-form" readonly>
							<s-text label="Cliente" v-model="items.UwoCustomerName" readonly></s-text>
						</v-col>

                        <v-col class="s-col-form">
							<s-text label="Producto" v-model="items.TypeCultiveName" readonly></s-text>
						</v-col>

                        <v-col class="s-col-form" >
							<s-text label="Variedad" v-model="items.VrtName" readonly></s-text>
						</v-col>

                        <v-col  class="s-col-form">
							<s-text label="Tipo de Caja" v-model="items.PckTypeBoxe" readonly></s-text>
						</v-col>

                        <v-col class="s-col-form" >
							<s-text label="Cantidad de Cajas" v-model="items.PckQuantityBoxe" readonly></s-text>
						</v-col>

                        <!-- <v-col lg="1" class="s-col-form" cols="6" md="4">
							<s-text label="Hora Inicio Carga" v-model="items.PckInitialCharge"></s-text>
						</v-col>

                        <v-col lg="1" class="s-col-form" cols="6" md="4">
							<s-text label="Hora Fin Carga" v-model="items.PckFinCharge"></s-text>
						</v-col> -->

						
					</v-row>
					<v-row>
						<v-col class="s-col-form">
							<s-text label="Contenedor N°" v-model="items.PckContainer" ref="PckContainer"></s-text>
						</v-col>

						<v-col class="s-col-form">
							<s-text label="N° Embarque" v-model="items.PckShipmentNumber" ref="PckShipmentNumber"></s-text>
						</v-col>

						<v-col class="s-col-form">
							<s-text label="Temp. Seteo de Cntr" decimal v-model="items.PckTemperatureSeteo" ref="PckTemperatureSeteo"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Precinto Aduanas" v-model="items.PckSealCustom" ref="PckSealCustom"></s-text>
						</v-col>

                        <v-col class="s-col-form">
							<s-text label="Precinto Naviera" v-model="items.PckSealShiping" ref="PckSealShiping"></s-text>
						</v-col>

                        <v-col class="s-col-form" >
							<s-text label="Precinto Senasa/Aphis" v-model="items.PckSealSenasa" ref="PckSealSenasa"></s-text>
						</v-col>

                        <v-col  class="s-col-form" >
							<s-text label="Precinto Botella" v-model="items.PckSealBottle" ref="PckSealBottle"></s-text>
						</v-col>
					</v-row>

					<v-row>
						<v-col  class="s-col-form" >
							<s-text label="Precinto Cable" v-model="items.PckSealCable" ref="PckSealCable"></s-text>
						</v-col>

                        <v-col  class="s-col-form" >
							<s-text label="Stickers" v-model="items.PckSticker"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Pallet 01" v-model="items.PckTempPallet01"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Pulpa 01" v-model="items.PckTempPulpa01"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Pallet 02" v-model="items.PckTempPallet02"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Pulpa 02" v-model="items.PckTempPulpa02"></s-text>
						</v-col>
						
					</v-row>

					<v-row>
						<v-col  class="s-col-form" >
							<s-text label="Pallet 03" v-model="items.PckTempPallet03"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Pulpa 03" v-model="items.PckTempPulpa03"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="T. Prom. Pulpa" v-model="items.PcktTempPromPulpa"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Humedad %" v-model="items.PckHumidity"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Ventilación CBM/CBH" v-model="items.PckVentilation"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="Cant. Filtros" v-model="items.PckQuantityFilter"></s-text>
						</v-col>
					</v-row>

					<v-row>
						<v-col  class="s-col-form" >
							<s-text label="N°Termoregistro/ Pallet" v-model="items.PckTermoRegisterPallet01"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-text label="N°Termoregistro/ Pallet" v-model="items.PckTermoRegisterPallet02"></s-text>
						</v-col>

						<v-col  class="s-col-form" >
							<s-select-definition
								label="Conforme"
								:def="1385"
								v-model="items.TypeConformity"
							></s-select-definition>
							<!-- <s-text label="Conforme" v-model="items.TypeConformity"></s-text> -->
						</v-col>

						<v-col class="s-col-form">
							<s-textarea
								label="Observaciones"
								v-model="items.PckObservation"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
			</v-col>

			<v-col cols="12" >
				<v-toolbar dense>
					<v-toolbar-title
						>
						<span style="margin-right: 15px">
							<v-switch class="mt-4" v-if="modeGrap" style="display: inline-block;" label="Scannear QR" v-model="scann"></v-switch>
						</span>
						<span>
							<v-switch class="mt-4" style="display: inline-block;" label="Dibujar Carga" v-model="modeGrap" @click="clickPositionDrag()"></v-switch>
						</span>
						
					</v-toolbar-title>
				</v-toolbar>
				<v-row justify="center" style="margin: auto" v-if="modeGrap"> 
							<v-col
								align="center"
								cols="12"
								lg="8"
								md="8"
								v-if="scann"
							>
								<s-scanner-qr
									:config="configScann"
									autofocus
									return-object
									@onValue="onValue($event)"
									:readonly="false"
								></s-scanner-qr>
							</v-col>
				</v-row>
				<!-- <v-row justify="center" style="margin: auto" v-if="modeGrap">
					<v-col>
						<v-data-table
							:headers="headersPallets"
							:items="items.itemsDetail"
							item-key="PltID"
						>
							<template v-slot:item.accion="{ item }">
								<v-btn
									x-small
									color="info"
									fab
								>
									<v-icon style="font-size: 16px !important"
										>fa fa-plus</v-icon
									>
								</v-btn>
							</template>

							<template v-slot:item.Description="{ item }">
								{{item.TypeZonLocationName}}, {{item.TypeCultiveName}} - {{item.VrtName}}
							</template>
						</v-data-table>
					</v-col>
				</v-row> -->
			</v-col>

			<v-col align="center" cols="12" v-if="modeGrap">
				<v-card flat :disabled="items.PckState == 2">
					<v-row justify="center">
						<v-col align="center" cols="12" lg="12" md="12">
							<drag-drop
								:dropzones="itemsPositionAll"
								:originalData="itemsDetail"
								:dropzonesTitle="'UBICACIÓN DE PALLET EN CONTENEDOR'"
								:inPlace="true"
								:enableSave="true"
								@save="saveDrap"
								:itemsReceived="itemsDetail"
							>
								<template #dd-card="{ cardData }">
									<custom-card-shipment
										:data="cardData"
										@viewTraceability="viewTraceability($event)"
										@viewAddPallettPosition="viewAddPallettPosition($event)"
									/>
								</template>
							</drag-drop>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog width="1000" v-model="showTraceability" v-if="showTraceability">
			<prf-traceability-view :selected="itemsTraceability" @close="showTraceability = false"></prf-traceability-view>
		</v-dialog>

		<v-dialog width="1000" v-model="showAsignPosition" v-if="showAsignPosition">
			<v-card>
				<s-toolbar
					label="Asignar posición"
					close
					@close="closeViewAddPalletPosition()"
					save
					@save="addPalletPosition()"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<s-text
								label="Posición"
								number
								v-model="position"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
				
			</v-card>
		</v-dialog>

	</v-card>

	

	

	<!-- </v-row> -->
</template>
<script>
	import _sPackingShipment from "../../../services/FreshProduction/PackingShipmentService";
	import CustomCardShipment from "./CustomCardShipment.vue";
	import PrfTraceabilityView from '@/views/FreshProduction/PrfTraceability/PrfTraceabilityView.vue'

	export default {
		props: {
			items: {
				require: true,
				default: null,
			},
		},
		components: {CustomCardShipment, PrfTraceabilityView },

        created() {
            this.itemsDetail = this.items.itemsDetail
			console.log('detalles', this.itemsDetail);
            this.generatedPositions();
        },

		data () {
            return {
                scann: true,
                configScann: {},

                modeGrap:false,
                itemsPositionAll: [
                ],

                itemsDetail: [],
                positions: 22,
				showTraceability: false,
				showAsignPosition: false,
				itemsTraceability: {},
				headersPallets:[
					{text: "Acción", value: 'accion'},
					{text: "Pallet", value: 'PltID'},
					{text: "Cantidad", value: 'UwoMaterialListNumberBoxes'},
					{text: "Calibre", value: 'TypeCaliberName'},
					{text: "Descripción", value: 'Description'},
					{text: "Fecha", value: 'PltDate'},
				],
				palletPrePosition: {},
				position: 0,
            }
		},

        methods: {
			addPalletPosition()
			{

				this.itemsDetail.forEach(element => {
					if(element.PltID == this.palletPrePosition.PltID)
					{
						element.PppPositionNumber = parseInt(this.position - 1);
					}
				});

				this.itemsPositionAll.forEach(element => {
					//element.children = []

					var obkkk = this.itemsDetail.find((x) => x.PppPositionNumber == element.id);

					if(obkkk !== undefined){
						if(element.children.length > 0)
						{
							this.$fun.alert("Advertencia, La posición " + (this.position) + " ya esta asignado1", "warning");
							return
						}
						else{
							element.children.push(obkkk)

							this.itemsDetail = this.itemsDetail.filter(
								x => x.PltID != obkkk.PltID,
							);
							this.position = 0;
							this.showAsignPosition = false;
						}
						
					}
					else
					{
						if(element.children.length > 0){
							if(element.children[0].PltID == this.palletPrePosition.PltID)
							{
								
								if(this.itemsPositionAll[parseInt(this.position) - 1].children.length > 0)
								{
									/* this.$fun.alert("Advertencia, La posición " + (this.position) + " ya esta asignado2", "warning"); */
									return
								}
								this.palletPrePosition.PppPositionNumber = parseInt(this.position) - 1;
								element.children.pop();
								this.itemsPositionAll[parseInt(this.position) - 1].children.push(this.palletPrePosition)
								this.showAsignPosition = false;
								//this.palletPrePosition.PppPositionNumber = parseInt(this.position - 1);
							}
						}
					}
					
				});
			},

            close(){
                this.$emit('closeShipment')
            },

			viewAddPallettPosition(data)
			{
				this.palletPrePosition = data;
				this.showAsignPosition = true;
			},

			closeViewAddPalletPosition()
			{
				this.showAsignPosition = false;
			},

            generatedPositions(){
               for (var i = 0; i < this.positions; i++) {
                    var objrrr = {id: i, name: "Posición 0" + (i+1), children: []}
                    this.itemsPositionAll.push(objrrr)
                }
            },

            clickPositionDrag(){

                if(this.modeGrap){
                    this.itemsDetail = this.items.itemsDetail

                    this.itemsPositionAll.forEach(element => {
                        
                        element.children = []
                        var obkkk = this.itemsDetail.find((x) => x.PppPositionNumber == element.id)

                        if(obkkk !== undefined){
                            element.children.push(obkkk)

                            this.itemsDetail = this.itemsDetail.filter(
                                x => x.PltID != obkkk.PltID,
                            );
                        }
                        

                        
                    });

                    
                }
                
            },

            saveDrap(received){
                
                if (received.dropzones.length > 0) {
					received.dropzones.forEach((element) => {
						element.children.forEach((children) => {
                            children.PppID = 111
							children.PppPositionNumber = element.id;
                            children.SecStatus = 100;
							children.UsrCreateID = this.$fun.getUserID(); 

							_sPackingShipment
								.updatePositionPallet(children, this.$fun.getUserID())
								.then((resp) => {
									this.$fun.alert(
										"Cambios guardados correctamente",
										"success"
									);
								});

						});
					});
				}
            },

            onValue(val){
                if(val > 0){
                    this.itemsPositionAll.forEach(element => {
                        if(element.children.length > 0){
                            var obj = element.children.find((x) => x.PltID == val);
                            if(obj !== undefined){
                                this.$fun.alert("Validación correcta. Pallet N° " + val + " se encuentra dentro de Pre-Packing", "success")
                                return
                            }
							else
							{
								this.$fun.alert("Advertencia. Pallet N° " + val + " No se encuentra dentro de Pre-Packing", "warning")
                                return
							}
                        }
						else{
							let foundPallet = this.itemsDetail.filter( element => {
								return element.PltID == val;
							})
							if(foundPallet.length > 0)
							{
								this.$fun.alert("Validación Correcta. Pallet N° " + val + " se encuentra dentro de Pre-Packing sin una posición asignada", "success");
								this.viewAddPallettPosition(foundPallet[0])
                                return
							}
						}
                    });
                    
                }
                
            },

            isValidated(){
                var isValid = true;

                if(this.items.PckContainer.length == 0){
                    this.$refs.PckContainer.error("Ingrese N° Contenedor")
                    isValid = false
                    return
                }

				if(this.items.PckShipmentNumber.length == 0){
                    this.$refs.PckShipmentNumber.error("Ingrese N° Embarque")
                    isValid = false
                    return
                }

                if(this.items.PckTemperatureSeteo <= 0){
                    this.$refs.PckTemperatureSeteo.error("Ingrese Temperatura de Contenedor")
                    isValid = false
                    return
                }

                if(this.items.PckSealCustom.length == 0){
                    this.$refs.PckSealCustom.error("Ingrese Precinto aduanas")
                    isValid = false
                    return
                }

                if(this.items.PckSealShiping.length == 0){
                    this.$refs.PckSealShiping.error("Ingrese Precinto Naviera")
                    isValid = false
                    return
                }

                if(this.items.PckSealSenasa.length == 0){
                    this.$refs.PckSealSenasa.error("Ingrese Precinto Senasa")
                    isValid = false
                    return
                }

                if(this.items.PckSealBottle.length == 0){
                    this.$refs.PckSealBottle.error("Ingrese Precinto Botella")
                    isValid = false
                    return
                }

                if(this.items.PckSealCable.length == 0){
                    this.$refs.PckSealCable.error("Ingrese Precinto Cable")
                    isValid = false
                    return
                }
                return isValid
            },

            save(){

                if(!this.isValidated()){return}

                this.items.SecStatus = 1;
                this.items.UsrCreateID = this.$fun.getUserID();
                this.items.itemsDetail = null

                this.$fun.alert("¿Seguro de Guardar?", "question").then((val) => {
                    if(val.value){
                        _sPackingShipment
								.save(this.items, this.$fun.getUserID())
								.then((resp) => {
                                    if(resp.status == 200){
                                        this.$fun.alert(
                                            "datos actualizados correctamente",
                                            "success"
									    );
                                    }
									
								});
                    }
                })
                


                console.log("Guardar", this.items);
            },

			viewTraceability(data){
				
				

				_sPackingShipment.GetTraceabilityPalelt(data.PltID, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){
						this.itemsTraceability = resp.data
						this.showTraceability = true
						console.log("data", this.itemsTraceability);
					}
				})
				
			}
		},
	};
</script>
