<template>
	<div class="cc-card">

		<v-btn
			x-small
			elevation="0"
			fab
			dark
			color="info"
			style="margin-left: -15px; margin-top: -20px"
			@click="viewTraceability()"
		>
			<v-icon style="font-size: 16px !important"
				>fas fa-eye</v-icon
			>zzz
		</v-btn>

		<v-btn
			x-small
			elevation="0"
			fab
			dark
			color="red"
			style="margin-left: -15px; margin-top: -20px"
			@click="viewAddPallettPosition()"
		>
			<v-icon style="font-size: 16px !important"
				>fas fa-plus</v-icon
			>
		</v-btn>

		<h3>
			PA : {{data.PltID  +'-'+  data.PltCorrelative }}
    
		</h3>
		<p style="-webkit-text-stroke: thick; font-size: 12px">
			{{data.UwoMaterialListNumberBoxes}}
		</p>
        <b>{{data.TypeCaliberName}}</b>
        <br>
        <b style="font-size: 10px">{{data.TypeZonLocationName}}, {{data.TypeCultiveName}} - {{data.VrtName}}</b>
		<br>
        <b style="font-size: 10px">{{data.PltDate}}</b>
		
	</div>
</template>

<script>
	export default {
		
		props: {data: null},
		methods: {
			viewTraceability(){
				this.$emit('viewTraceability', this.data)
			},

			viewAddPallettPosition()
			{
				this.$emit("viewAddPallettPosition", this.data)
			}
		},
	};
</script>
<style>
	.cc-card {
		padding: 10px;
		text-align: left;
		border: 1px solid #dbdbdb;
		border-radius: 5px;
		margin: 5px;
		background-color: white;
		min-width: 200px;
		font-size: 1em;
	}

	.cc-card h3 {
		margin: 0px;
	}
</style>